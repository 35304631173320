<template>
    <div class="video-management">
        <div class="video-management-content">
            <div class="video-management-header">
                <span>视频发布</span>
            </div>
            <div class="select-content">
                <div class="item">
                    <span class="sort-text">平台：</span>
                    <div class="img-item" :class="{current: currentPublishPlatform === selectItem.id}"
                         v-for="selectItem in selectPublishPlatform"
                         :key="selectItem.id"
                         @click="choosePublishPlatform(selectItem)">
                        <img class="type-img" :src="selectItem.src">
                        <img class="img-item-selected" src="@/assets/img/image/platform-selected.png">
                    </div>
                </div>
                <div class="item item-account account-main" style="width: 100%;">
                    <div class="account-title">
                        <span class="sort-text">账号：</span>
                    </div>
                    <div class="account-list">
                        <div class="img-item" :class="{current: currentAccount === selectItem.id}"
                             v-for="selectItem in selectPublishAccount"
                             :key="selectItem.id"
                             @click="choosePublishAccount(selectItem.id)">
                            <img class="type-img" :src="selectItem.avatar">
                            <img class="img-item-selected" src="@/assets/img/image/platform-selected.png">
                        </div>
                    </div>
                </div>
                <el-form :model="publishVideoForm" ref="publishVideoForm" label-position="center" class="info-form">
                    <el-form-item label="视频内容:">
                        <div class="add-video" v-if="parseInt(this.$route.query.ShortTab) === 1">
                            <div class="up-img-show" v-show="publishVideoForm.video">
                                <span>{{publishVideoForm.videoFileName}}</span>
                                <span class="video-text">{{publishVideoForm.videoFileSize}}M</span>
                            </div>
                            <el-button type="primary" v-model="publishVideoForm.video" class="upload-btn-video" size="medium">
                                <span>{{publishVideoForm.video ? '重新上传' : '上传视频'}}</span>
                                <input type="file" accept="video/*" id="videoId" @change="changeVideo($event)">
                            </el-button>
                        </div>
                        <div class="video-show" v-if="fileName.length > 0">
                            <div class="video-title-desc">
                                <i class="el-icon-video-camera"></i>
                                <span>{{fileName}}</span>
                            </div>
                            <div class="video-process">
                                <el-progress :percentage="percent"></el-progress>
                            </div>
                        </div>
                        <div class="video-request">
                            <span>1.支持常用视频格式，推荐使用mp4、webm</span>
                            <span>3.分辨率为720p(1280×720)及以上</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="上传封面:">
                        <div style="width: 300px; height: 150px;display: flex;justify-content: center;align-items: center;position: relative;" @click="uploadCoverEvt">
                            <div class="upload-icon" v-if="!imageSrc.length" >
                                <i class="el-icon-upload"></i>
                                <input type="file" id="cover" ref="cover" @change="uploadCoverChange"  />
                            </div>
                            <div class="image-cover" v-else>
                                <img :src="imageSrc" alt="">
                            </div>
                            <div class="progress-line" :style="{width: imageProgress}" v-if="!imageSrc.length"></div>
                        </div>
                        <div class="video-request">
                            <span>1.封面没有上传，则视频的第一帧为封面</span>
                            <span>2.格式jpg、jpeg、png，文件大小≤5MB，最低尺寸≥960*600</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="视频标题:">
                        <el-input v-model="publishVideoForm.videoTitle" autocomplete="off" placeholder="请输入视频标题" class="video-title-input"></el-input>
                        <el-button type="primary" class="add-video-title" size="medium" @click="addVideoTopic">#添加话题</el-button>
                    </el-form-item>
                    <el-form-item class="publish-btn">
                        <el-button type="primary" @click="onSubmit">发布</el-button>
                        <el-button @click="cancelPublish">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import ImgCutter from 'vue-img-cutter'
import {mapGetters} from 'vuex';
import {completePartUpload, encryptData, initOpenDouyinPartUpload, createVideo} from "@/utils/apis";
import {decryptData} from "@/utils/common";
import {douyinPartUpload, uploadImage, uploadVideo} from "@/utils/douyinRequest";

export default {
    name: "VideoPublish",
    data() {
        return {
            //平台选择
            selectPublishPlatform: [
                {
                    id: 1,
                    name: '抖音',
                    src: require('@/assets/img/image/platform-1.png')
                },
                {
                    id: 2,
                    name: '快手',
                    src: require('@/assets/img/image/platform-2.png')
                },
                {
                    id: 3,
                    name: '哔哩哔哩',
                    src: require('@/assets/img/image/platform-3.png')
                },
                {
                    id: 4,
                    name: '美拍',
                    src: require('@/assets/img/image/platform-6.png')
                },
                {
                    id: 5,
                    name: '西瓜',
                    src: require('@/assets/img/image/platform-5.png')
                },
                {
                    id: 6,
                    name: '火山视频',
                    src: require('@/assets/img/image/platform-4.png')
                }
            ],
            currentPublishPlatform: 1,
            //账号列表
            selectPublishAccount: [],
            currentAccount: null,
            //发布表单
            publishVideoForm: {
                id: '',
                video: '',
                video_id: '',
                videoFile: '',
                videoFileName: '',
                videoFileSize: '',
                videoUrl: this.$route.query.file_path || '',
                coverSrc: null,
                videoTitle: '',
                publishTime: ''
            },
            //发布时间开关
            publishTimeValue: true,
            //从剪辑库传过来的视频路径
            clip_video_path: '',
            clip_video_path_src: '',
            access_token: "",
            open_id: '',
            percent: 0,
            fileName: '',
            imageSrc: "",
            coverImageId: "",
            videoId: "",
            imageProgress: "0%"
        }
    },
    components:{
        ImgCutter
    },
    computed: {
        ...mapGetters(['filePath','filePathUrl'])
    },
    mounted() {
        this.getPlatformAccount();
    },
    methods: {
        uploadCoverEvt() {
            this.$refs.cover.click()
        },
        async uploadCoverChange(evt) {
            const file = evt.target.files[0]
            if (file.size > 5 * 1024 * 1024) {
                this.$message.warning("封面图片不能超过5M")
                return
            }
            const res = await uploadImage(this.access_token, this.open_id, file, (progressEvt) => {
                this.imageProgress = Math.round((progressEvt.loaded * 100) / progressEvt.total) + '%'
            })
            if (res.data.error_code === 0) {
                this.coverImageId = res.data.image.image_id;
                this.imageSrc = window.URL.createObjectURL(file)
            } else {
                this.$message.warning(res.data.description)
            }
        },
        async initData() {
            const body = {
                account_id: this.currentAccount
            }
            const res = await encryptData(body)
            console.log("res", res)
            if (res.code === 200) {
                const result = decryptData(res.data.encrypt)
                this.access_token = result.access_token
                this.open_id = result.open_id
            }
        },
        //选择平台
        choosePublishPlatform(item) {
            this.currentPublishPlatform = item.id;
            this.getPlatformAccount()
        },
        //选择账号
        choosePublishAccount(id) {
            this.currentAccount = id;
            this.initData()
        },
        //获取平台账号
        getPlatformAccount() {
            let params = {
                platform_type: this.currentPublishPlatform,
                type: 1
            }
            this.$httpStudent.axiosGetBy(this.$api.get_account, params, (res) => {
                if (res.code === 200) {
                    this.selectPublishAccount = res.data.data;
                    if (res.data.data.length > 0) {
                        this.currentAccount = res.data.data[0].id;
                        this.initData()
                    }
                } else {
                    this.$message.error(res.message);
                }
            }, (err) => {
                console.log(err);
            })
        },
        //上传视频
        async changeVideo(event) {
            console.log("currentAccount", this.currentAccount)
            this.percent = 0;
            // return
            if (event.target.files.length === 0) return;
            let fileList = event.target.files[0];
            console.log(fileList.type.split("/"))
            const typeList = fileList.type.split("/")
            const _type = typeList[typeList.length - 1]
            if (["mp4", "webm"].indexOf(_type) === -1) {
                this.$message.warning('视频格式不支持')
                return
            }
            this.fileName = fileList.name
            if (fileList.size < 12 * 1024 * 1024) {
                this.uploadOne(fileList)
                return
            }
            const params = {
                account_id: this.currentAccount
            }
            const res = await initOpenDouyinPartUpload(params)
            console.log("res", res)
            if (res.code !== 200) {
                this.$message.warning(res.msg)
                return
            }
            const upload_id = res.data.upload_id;
            const encode_upload_id = encodeURIComponent(upload_id)

            const chunkSize = 6 * 1024 * 1024
            const remainder = fileList.size % chunkSize
            let totalChunk = Math.ceil(fileList.size / chunkSize)
            if (remainder < 5 * 1024 * 1024) {
                //抖音开放者平台分片上传最小不能小于5M，所有当最后一片分片是小于5M的话，要将最后一片分片
                //和倒数第二片分片合并
                totalChunk = totalChunk - 1;
            }
            console.log("总数", totalChunk)
            console.log("文件总大小", fileList.size)
            for (let currentChunk = 0; currentChunk < totalChunk; currentChunk++) {
                const start = currentChunk * chunkSize
                // const end =  Math.min(start + chunkSize, fileList.size)
                const end =  currentChunk === (totalChunk - 1) ? fileList.size : (start + chunkSize)
                console.log("start", start)
                console.log("end", end)
                const chunk = fileList.slice(start, end);
                try {
                    const res = await douyinPartUpload(this.access_token, this.open_id, encode_upload_id, chunk, currentChunk + 1)
                    this.percent = Number((((currentChunk + 1) / totalChunk) * 100).toFixed(2))
                    console.log("分片上传res：", res)
                } catch (e) {
                    console.log("e", e)
                }
            }
            try {
                const params = {
                    account_id: this.currentAccount,
                    upload_id: upload_id
                }
                console.log("params",params)
                const res_complete = await completePartUpload(params)
                if (res_complete.code === 200) {
                    console.log("res_complete", res_complete.data)
                    this.percent = 100
                    this.videoId = res_complete.data.video.video_id
                }
            } catch (e) {
                console.log("e", e)
            }
        },
        //文件大小小于5M的视频
        async uploadOne(file) {
            try {
                const form_data = new FormData()
                form_data.append("video", file)
                const res = await uploadVideo(this.access_token, this.open_id, form_data, (progressEvt) => {
                    this.percent = Math.round((progressEvt.loaded * 100) / progressEvt.total)
                })
                if (res.data.error_code === 0) {
                    console.log("res", res.data)
                    this.videoId = res.data.video.video_id
                } else {
                    this.$message.warning(res.data.description)
                }
            } catch (e) {
                this.$message.warning("服务异常")
                console.log("err",e)
            }
        },
        //添加话题
        addVideoTopic() {
            this.publishVideoForm.videoTitle += '#';
        },
        //发布
        async onSubmit() {
            try {
                console.log("提交参数", this.videoId, this.publishVideoForm.videoTitle, this.coverImageId)
                const json_data = {
                    "video_id": this.videoId,
                    "text": this.publishVideoForm.videoTitle,
                    "account_id": this.currentAccount
                }
                if (this.coverImageId.length > 0) {
                    json_data.image_id = this.coverImageId
                }
                console.log("json_data", json_data)
                const res = await createVideo(json_data)
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "发布成功,请在视频管理-更新列表",
                        onClose: () => {
                            this.$router.push("/student/video/management")
                        }
                    })
                } else {
                    this.$message.warning(res.msg + "请检查用户状态是否有问题")
                }
            } catch (e) {
                this.$message.warning("服务异常")
                console.log("err", e)
            }
        },
        //取消发布
        cancelPublish() {
            this.$router.push({
                path: '/student/video/management',
            });
        }
    }
}
</script>
<style scoped lang="scss">
.account-main {
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    .account-title {
        padding-top: 10px;
        width: 50px;
    }
    .account-list {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }
}
.progress-line {
    height: 2px;
    background: #594FEE;
    position: absolute;
    bottom: 0;
    left: 0;
}
.upload-icon {
    width: 100%;
    height: 100%;
    border: 1px dashed #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    i {
        font-size: 30px;
        color: #594FEE;
    }
    #cover {
        display: none;
    }
}
.image-cover {
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}
.video-management-content {
    background-color: #fff;
    padding-top: 35px;
    .video-management-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-family: "Microsoft YaHei",serif;
        font-weight: 400;
        margin: 0 35px;
        padding-bottom: 19px;
        .publish-video {
            background-color: #594FED;
            border-color: #594FED;
            border-radius: 15px;
            &:hover {
                background-color: #463bdc;
                border-color: #463bdc;
            }
        }
    }
    .select-content {
        ::v-deep .el-checkbox-group {
            display: flex;
            .img-item {
                .el-checkbox__label {
                    line-height: 10px;
                    img {
                        width: 38px;
                        height: 38px;
                    }
                }
            }
        }
        ::v-deep .el-checkbox {
            width: 63px !important;
            display: flex;
            justify-content: start;
            align-items: center;
        }
        padding: 0 35px 0 45px;
        .item {
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin: 20px 0 20px 0;
            color: #666;
        }
        .item-account {
            border-bottom: 1px solid #D1D2DB;
            padding-bottom: 20px;
            ::v-deep .el-checkbox-group {
                .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #9ACC5C;
                    border-color: #9ACC5C;
                    &:hover {
                        border-color: #9ACC5C;
                    }
                }
                .el-checkbox__inner {
                    &:hover {
                        border-color: #9ACC5C;
                    }
                }
            }
        }
        .sort-item {
            display: inline-block;
            padding: 2px 6px;
            margin: 0 5px;
        }
        .sort-item.current, .sort-item:hover {
            background: #9ACC5B;
            color: #fff;
            border-radius: 5px;
            cursor: pointer;
        }
        .img-item {
            background: transparent !important;
            width: 38px;
            height: 38px;
            border-radius: 50% !important;
            margin-right: 12px;
            margin-top: 12px;
            cursor: pointer;
            position: relative;
            img.type-img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            img.img-item-selected {
                position: absolute;
                display: none;
                bottom: -2px;
                right: -2px;
                width: 14px;
                height: 14px;
            }
        }
        .current {
            img.img-item-selected {
                display: block;
            }
        }
        .info-form {
            min-height: calc(100vh - 346px);
            padding-bottom: 10px;
            .add-video {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .up-img-show {
                    margin-right: 200px;
                    .video-text {
                        color: #BCB9CB;
                        margin-left: 20px;
                    }
                }
            }
            .video-request {
                display: flex;
                flex-direction: column;
                color: #BCB9CB;
                font-size: 14px;
                margin-left: 75px;
            }
            .video-cover {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 120px;
                height: 120px;
                background: #ECEBF9;
                border-radius: 10px;
                cursor: pointer;
                i {
                    color: #9ACC5B;
                    font-size: 22px;
                }
                .el-upload__text {
                    font-size: 12px;
                    color: #5F5C74;
                }
            }
            .video-show {
                margin: 0 0 10px 75px;
                width: 300px;
                .video-title-desc {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    i {
                        font-size: 20px;
                    }
                    span {
                        margin-left: 10px;
                        font-size: 14px;
                    }
                }
            }
            .cover-img {
                display: flex;
                align-items: center;
                .img {
                    width: 450px;
                    height: 300px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .video-title-input {
                width: 270px;
                margin-right: 10px;
                ::v-deep .el-input__inner {
                    width: 270px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 10px;
                }
            }
            .add-video-title {
                padding: 6px 12px;
                background-color: #BCB9CB;
                border-color: #BCB9CB;
                font-size: 12px;
                border-radius: 10px;
                &:hover {
                    background-color: #b1b0b7;
                    border-color: #b1b0b7;
                }
            }
            .publish-time {
                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                    border-radius: 10px;
                    margin-left: 10px;
                }
                ::v-deep .el-input__prefix {
                    left: 15px;
                }
            }
            .upload-btn-video {
                position: relative;
                padding: 7px 16px;
                border-radius: 15px;
                background: #594FEE;
                border-color: #594FEE;
                cursor: pointer;
                &:hover {
                    background: #2338E6;
                    border-color: #2338E6;
                }
                input {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
            .publish-btn {
                ::v-deep .el-button {
                    padding: 7px 30px;
                    border-radius: 15px;
                    cursor: pointer;
                }
                .el-button--primary {
                    background: #594FEE;
                    border-color: #594FEE;
                    &:hover {
                        background: #2338E6;
                        border-color: #2338E6;
                    }
                }
                .el-button--default {

                }
            }
        }
    }
}
</style>
